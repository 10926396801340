<template>
  <nav class="pagination is-centered" role="navigation" aria-label="pagination">
    <button
      class="pagination-previous"
      @click="goToPreviousPage"
      :disabled="currentPage <= 1"
    >
      <span class="icon">
        <font-awesome-icon :icon="['fas', 'chevron-left']"
      /></span>
      <span>Previous</span>
    </button>
    <button
      class="pagination-next"
      @click="goToNextPage"
      :disabled="mPage >= totalPages"
    >
      <span>Next</span>
      <span class="icon">
        <font-awesome-icon :icon="['fas', 'chevron-right']"
      /></span>
    </button>

    <ul class="pagination-list">
      <li v-for="(pageNumber, index) in pageNumbers" :key="'p_' + index">
        <a
          v-if="!isNaN(pageNumber)"
          @click="goToPage(pageNumber)"
          class="pagination-link"
          :class="{ 'is-current': pageNumber === mPage }"
          >{{ pageNumber }}</a
        >
        <span v-if="isNaN(pageNumber)" class="pagination-ellipsis has-text-grey"
          >&hellip;</span
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      default: 1,
      type: Number,
    },

    morePages: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      mPage: this.currentPage,
      mMorePages: this.morePages,
      pageNumbers: [],
      totalPages: 0,
    }
  },

  computed: {},

  watch: {
    currentPage: function(val) {
      // Update mutable page variable
      this.mPage = val
      // And rebuild pagination
      this.buildPagination()
    },

    morePages: function(val) {
      // Update mutable morePages variable
      this.mMorePages = val
      // And rebuild pagination
      this.buildPagination()
    },
  },

  created() {
    this.buildPagination()
  },

  methods: {
    buildPagination() {
      let page = this.mPage
      this.totalPages = this.mPage + this.mMorePages
      let delta = 4
      let left = page - delta
      let right = page + delta
      let result = []

      result = Array.from({ length: this.totalPages }, (v, k) => k + 1).filter(
        (i) => i && i >= left && i < right
      )

      if (result.length > 1) {
        // Add first page and dots
        if (result[0] > 1) {
          if (result[0] > 2) {
            result.unshift('...')
          }
          result.unshift(1)
        }

        // Add dots and last page
        if (result[result.length - 1] < this.totalPages) {
          if (result[result.length - 1] !== this.totalPages - 1) {
            result.push('...')
          }
          result.push(this.totalPages)
        }
      }

      this.lastPageNumber = result.length - 1
      this.pageNumbers = result
    },

    goToPreviousPage() {
      this.goToPage(this.mPage - 1)
    },

    goToNextPage() {
      this.goToPage(this.mPage + 1)
    },

    goToPage(page) {
      this.mPage = page
      this.mMorePages = this.totalPages - page
      this.buildPagination()
      this.$emit('pageSelected', this.mPage)
    },
  },
}
</script>
